import {Json} from "@/layouts/workspace/dashboard/table";

export const tagsJieRi = {
    'j_nsj': '女神节',
    "j_wyldj": "劳动节",
    'j_dwj': '端午节',
    'j_618': '618',
    'j_zqj': '中秋节',
    'j_gqj': '国庆节',
    'j_ssy': '双11',
    'j_1212': '双12',
    'j_yd': '元旦',
    'j_mqj': '母亲节',
    'j_sdj': '圣诞节',
    'j_gej': '感恩节',
}

export const tagsMoShi = {
    'm_ptt': '普通团',
    'm_jgjtt': '价格阶梯团',
    'm_spjtt': '商品阶梯团',
}

export const tagsGongJu = {
    'g_fx': '分销',
}

export const tagsHangYe = {
    't_sems': '少儿美术',
    't_sesf': '少儿书法',
    't_sezc': '少儿主持',
    't_sekc': '少儿口才',
    't_sewd': '少儿舞蹈',
    't_semt': '少儿模特',
    't_sety': '少儿体育',
    't_seyq': '少儿乐器',
    't_selg': '少儿乐高',
    't_sebc': '少儿编程',
    't_mf': '美发',
    't_mr': '美容',
    't_tdt': '头道汤',
    't_sk': '烧烤',
    't_jcc': '家常菜',
    't_mx': '米线',
    't_lsf': '螺蛳粉',
    't_mlt': '麻辣烫',
    't_hg': '火锅',
    't_zbhj': '珠宝黄金',
    't_hssy': '婚纱摄影',
    't_dg': '甜点',
    't_nc': '奶茶',
    't_mj': '美甲',
    't_zy': '足浴',
    't_cr': '采耳',
    't_xc': '洗车'
}

export const tagsMuDi = {
    't_xdky': '新店开业',
    't_znqd': '周年庆典',
    't_nzdc': '年中大促',
    // 't_bgyl': '曝光引流',
}

export const tags: Json = {...tagsJieRi, ...tagsMoShi, ...tagsGongJu, ...tagsHangYe, ...tagsMuDi}